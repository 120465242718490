// Code generated, DO NOT EDIT.
import * as Types from './index';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type AdminAuthQueryVariables = Types.Exact<{ [key: string]: never }>;

export type AdminAuthQuery = {
	__typename?: 'Query';
	adminAuth?: { __typename?: 'Admin'; id: number; email: string } | null;
};

export type AdminDashboardQueryVariables = Types.Exact<{ [key: string]: never }>;

export type AdminDashboardQuery = {
	__typename?: 'Query';
	adminDashboard: {
		__typename?: 'AdminDashboard';
		clubs: Array<{ __typename?: 'Club'; id: string; name: string; logo?: string | null }>;
		users: { __typename?: 'AdminUserStatistics'; total: number; active: number };
	};
	adminIdrottExports: {
		__typename?: 'IdrottBatches';
		batches: Array<{ __typename?: 'IdrottBatch'; id: string; status: Types.IdrottBatchStatus; created: string }>;
	};
	adminSupportTickets: Array<{ __typename?: 'SupportTicket'; id: string; state: Types.TicketStatus }>;
};

export type PaymentsFieldsFragment = {
	__typename?: 'ClubPayments';
	clubID: string;
	payments: Array<{
		__typename?: 'ClubPayment';
		id: string;
		date: string;
		amount: string;
		description?: string | null;
	}>;
};

export type AdminClubQueryVariables = Types.Exact<{
	id: Types.Scalars['ID'];
}>;

export type AdminClubQuery = {
	__typename?: 'Query';
	club?: {
		__typename?: 'Club';
		id: string;
		name: string;
		logo?: string | null;
		inserted: string;
		country: string;
		paymentsProvider: number;
		websiteFQDN: string;
	} | null;
	adminClubPayments: {
		__typename?: 'ClubPayments';
		clubID: string;
		payments: Array<{
			__typename?: 'ClubPayment';
			id: string;
			date: string;
			amount: string;
			description?: string | null;
		}>;
	};
	adminSupportTickets: Array<{
		__typename?: 'SupportTicket';
		id: string;
		state: Types.TicketStatus;
		priority: number;
		subject: string;
		message: string;
		inserted: string;
		updated: string;
		user: { __typename?: 'Member'; id: string; firstName: string; lastName: string };
		files: Array<{ __typename?: 'TicketFile'; id: string }>;
		images: Array<{ __typename?: 'TicketImage'; id: string }>;
	}>;
};

export type AdminSupportTicketsQueryVariables = Types.Exact<{
	limit?: Types.InputMaybe<Types.Scalars['Int']>;
	offset?: Types.InputMaybe<Types.Scalars['Int']>;
}>;

export type AdminSupportTicketsQuery = {
	__typename?: 'Query';
	adminSupportTickets: Array<{
		__typename?: 'SupportTicket';
		id: string;
		clubID: string;
		state: Types.TicketStatus;
		priority: number;
		subject: string;
		message: string;
		inserted: string;
		updated: string;
		user: { __typename?: 'Member'; id: string; firstName: string; lastName: string };
		files: Array<{ __typename?: 'TicketFile'; id: string }>;
		images: Array<{ __typename?: 'TicketImage'; id: string }>;
	}>;
};

export type AdminIdrottExportQueryVariables = Types.Exact<{
	id: Types.Scalars['ID'];
}>;

export type AdminIdrottExportQuery = {
	__typename?: 'Query';
	adminIdrottExport?: {
		__typename?: 'IdrottBatch';
		id: string;
		status: Types.IdrottBatchStatus;
		error?: string | null;
		created: string;
		updated: string;
	} | null;
};

export type AdminIdrottExportsQueryVariables = Types.Exact<{
	offset?: Types.InputMaybe<Types.Scalars['Int']>;
	limit?: Types.InputMaybe<Types.Scalars['Int']>;
}>;

export type AdminIdrottExportsQuery = {
	__typename?: 'Query';
	adminIdrottExports: {
		__typename?: 'IdrottBatches';
		total: number;
		batches: Array<{ __typename?: 'IdrottBatch'; id: string; status: Types.IdrottBatchStatus; created: string }>;
	};
};

export type CreateClubMutationVariables = Types.Exact<{
	input: Types.CreateClub;
}>;

export type CreateClubMutation = {
	__typename?: 'Mutation';
	createClub: { __typename?: 'Club'; id: string; name: string };
};

export type CreateAdminClubPaymentMutationVariables = Types.Exact<{
	input: Types.CreateAdminClubPayment;
}>;

export type CreateAdminClubPaymentMutation = {
	__typename?: 'Mutation';
	createAdminClubPayment: {
		__typename?: 'ClubPayments';
		clubID: string;
		payments: Array<{
			__typename?: 'ClubPayment';
			id: string;
			date: string;
			amount: string;
			description?: string | null;
		}>;
	};
};

export type EditAdminClubPaymentMutationVariables = Types.Exact<{
	input: Types.EditAdminClubPayment;
}>;

export type EditAdminClubPaymentMutation = {
	__typename?: 'Mutation';
	editAdminClubPayment: {
		__typename?: 'ClubPayments';
		clubID: string;
		payments: Array<{
			__typename?: 'ClubPayment';
			id: string;
			date: string;
			amount: string;
			description?: string | null;
		}>;
	};
};

export type DeleteAdminClubPaymentMutationVariables = Types.Exact<{
	input: Types.DeleteAdminClubPayment;
}>;

export type DeleteAdminClubPaymentMutation = {
	__typename?: 'Mutation';
	deleteAdminClubPayment: {
		__typename?: 'ClubPayments';
		clubID: string;
		payments: Array<{
			__typename?: 'ClubPayment';
			id: string;
			date: string;
			amount: string;
			description?: string | null;
		}>;
	};
};

export type EditAdminClubSupportTicketMutationVariables = Types.Exact<{
	input: Types.EditAdminClubSupportTicket;
}>;

export type EditAdminClubSupportTicketMutation = {
	__typename?: 'Mutation';
	editAdminClubSupportTicket: {
		__typename?: 'SupportTicket';
		id: string;
		state: Types.TicketStatus;
		priority: number;
		subject: string;
		message: string;
		inserted: string;
		updated: string;
		files: Array<{ __typename?: 'TicketFile'; id: string; name: string; size: number; inserted: string }>;
		images: Array<{ __typename?: 'TicketImage'; id: string; hash: string; inserted: string }>;
		comments: Array<{
			__typename?: 'TicketComment';
			comment: string;
			inserted: string;
			user?: { __typename?: 'Member'; id: string; avatar?: string | null; firstName: string; lastName: string } | null;
		}>;
		user: { __typename?: 'Member'; id: string; avatar?: string | null; firstName: string; lastName: string };
	};
};

export const PaymentsFieldsFragmentDoc = {
	kind: 'Document',
	definitions: [
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'paymentsFields' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClubPayments' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'clubID' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'payments' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'date' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'amount' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<PaymentsFieldsFragment, unknown>;
export const AdminAuthDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'AdminAuth' },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'adminAuth' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'email' } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<AdminAuthQuery, AdminAuthQueryVariables>;
export const AdminDashboardDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'AdminDashboard' },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'adminDashboard' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'clubs' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'logo' } },
										],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'users' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'total' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'active' } },
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'adminIdrottExports' },
						arguments: [
							{ kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '5' } },
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'batches' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'status' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'created' } },
										],
									},
								},
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'adminSupportTickets' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'state' } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<AdminDashboardQuery, AdminDashboardQueryVariables>;
export const AdminClubDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'AdminClub' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
					type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'club' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'id' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'logo' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'inserted' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'country' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'paymentsProvider' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'websiteFQDN' } },
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'adminClubPayments' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'clubID' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'paymentsFields' } }],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'adminSupportTickets' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'clubID' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'user' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
										],
									},
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'state' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'priority' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'subject' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'message' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'inserted' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'updated' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'files' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'images' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
									},
								},
							],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'paymentsFields' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClubPayments' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'clubID' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'payments' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'date' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'amount' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<AdminClubQuery, AdminClubQueryVariables>;
export const AdminSupportTicketsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'AdminSupportTickets' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'adminSupportTickets' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'offset' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'clubID' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'user' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
										],
									},
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'state' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'priority' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'subject' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'message' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'inserted' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'updated' } },
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'files' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
									},
								},
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'images' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
									},
								},
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<AdminSupportTicketsQuery, AdminSupportTicketsQueryVariables>;
export const AdminIdrottExportDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'AdminIdrottExport' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
					type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'adminIdrottExport' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'id' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'status' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'error' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'created' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'updated' } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<AdminIdrottExportQuery, AdminIdrottExportQueryVariables>;
export const AdminIdrottExportsDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'query',
			name: { kind: 'Name', value: 'AdminIdrottExports' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
				},
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
					type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'adminIdrottExports' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'offset' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
							},
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'limit' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'batches' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'status' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'created' } },
										],
									},
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'total' } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<AdminIdrottExportsQuery, AdminIdrottExportsQueryVariables>;
export const CreateClubDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'CreateClub' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateClub' } } },
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'createClub' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<CreateClubMutation, CreateClubMutationVariables>;
export const CreateAdminClubPaymentDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'CreateAdminClubPayment' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateAdminClubPayment' } },
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'createAdminClubPayment' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'paymentsFields' } }],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'paymentsFields' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClubPayments' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'clubID' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'payments' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'date' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'amount' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<CreateAdminClubPaymentMutation, CreateAdminClubPaymentMutationVariables>;
export const EditAdminClubPaymentDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'EditAdminClubPayment' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'EditAdminClubPayment' } },
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'editAdminClubPayment' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'paymentsFields' } }],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'paymentsFields' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClubPayments' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'clubID' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'payments' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'date' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'amount' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<EditAdminClubPaymentMutation, EditAdminClubPaymentMutationVariables>;
export const DeleteAdminClubPaymentDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'DeleteAdminClubPayment' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeleteAdminClubPayment' } },
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'deleteAdminClubPayment' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'paymentsFields' } }],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'paymentsFields' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClubPayments' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'clubID' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'payments' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'date' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'amount' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'description' } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<DeleteAdminClubPaymentMutation, DeleteAdminClubPaymentMutationVariables>;
export const EditAdminClubSupportTicketDocument = {
	kind: 'Document',
	definitions: [
		{
			kind: 'OperationDefinition',
			operation: 'mutation',
			name: { kind: 'Name', value: 'EditAdminClubSupportTicket' },
			variableDefinitions: [
				{
					kind: 'VariableDefinition',
					variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
					type: {
						kind: 'NonNullType',
						type: { kind: 'NamedType', name: { kind: 'Name', value: 'EditAdminClubSupportTicket' } },
					},
				},
			],
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'editAdminClubSupportTicket' },
						arguments: [
							{
								kind: 'Argument',
								name: { kind: 'Name', value: 'input' },
								value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
							},
						],
						selectionSet: {
							kind: 'SelectionSet',
							selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'fullTicketFields' } }],
						},
					},
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'ticketFields' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SupportTicket' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'user' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
							],
						},
					},
					{ kind: 'Field', name: { kind: 'Name', value: 'state' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'priority' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'subject' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'message' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'inserted' } },
					{ kind: 'Field', name: { kind: 'Name', value: 'updated' } },
				],
			},
		},
		{
			kind: 'FragmentDefinition',
			name: { kind: 'Name', value: 'fullTicketFields' },
			typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SupportTicket' } },
			selectionSet: {
				kind: 'SelectionSet',
				selections: [
					{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ticketFields' } },
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'files' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'name' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'size' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'inserted' } },
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'images' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'hash' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'inserted' } },
							],
						},
					},
					{
						kind: 'Field',
						name: { kind: 'Name', value: 'comments' },
						selectionSet: {
							kind: 'SelectionSet',
							selections: [
								{
									kind: 'Field',
									name: { kind: 'Name', value: 'user' },
									selectionSet: {
										kind: 'SelectionSet',
										selections: [
											{ kind: 'Field', name: { kind: 'Name', value: 'id' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
											{ kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
										],
									},
								},
								{ kind: 'Field', name: { kind: 'Name', value: 'comment' } },
								{ kind: 'Field', name: { kind: 'Name', value: 'inserted' } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<EditAdminClubSupportTicketMutation, EditAdminClubSupportTicketMutationVariables>;
