import { useQuery } from '@apollo/client';
import { AdminAuthDocument, AdminAuthQuery, AdminAuthQueryVariables } from '@skyfall/api/graphql/admin-dashboard';
import { createContext, useContext } from 'react';

export type User = {
	id: string;
	firstName: string;
	lastName: string;
};

const Context = createContext(null as AdminAuthQuery['adminAuth']);

export const AuthProvider = ({ children }) => {
	const { data: { adminAuth } = {}, loading } = useQuery<AdminAuthQuery, AdminAuthQueryVariables>(AdminAuthDocument);

	if (loading) return null;
	return <Context.Provider value={adminAuth ?? null}>{children}</Context.Provider>;
};

export const useAuth = () => useContext(Context);
