import { ApolloClient, ApolloProvider, from, InMemoryCache } from '@apollo/client';
import type { TypedTypePolicies } from '@skyfall/api/graphql';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { lazy, StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import './index.css';

const Router = lazy(() => import('./Router'));

const httpLink = createUploadLink({
	credentials: 'include',
	uri: `${process.env.API_URL}/graphql`,
});
const typePolicies: TypedTypePolicies = {
	ClubPayments: {
		keyFields: ['clubID'],
		fields: {
			payments: {
				merge(existing, incoming) {
					return incoming;
				},
			},
		},
	},
};
const client = new ApolloClient({
	cache: new InMemoryCache({ typePolicies }),
	link: from([httpLink]),
	connectToDevTools: true,
});

const root = createRoot(document.getElementById('app')!);
root.render(
	<StrictMode>
		<ApolloProvider client={client}>
			<AuthProvider>
				<BrowserRouter>
					<Suspense fallback={<div>{'Loading'}</div>}>
						<Router />
					</Suspense>
				</BrowserRouter>
			</AuthProvider>
		</ApolloProvider>
	</StrictMode>,
);
